<template>
  <div>
    <v-container fluid>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="blue accent-4"
      ></v-progress-linear>
      <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-row justify="center" align-sm="center" class="">
        <v-col sm cols="12" align-self="center" class="d-flex justify-center">
          <v-img
            alt="100 Miles Logo"
            class="shrink"
            contain
            src="../../assets/login-page-logo.png"
            transition="scale-transition"
            max-height="150"
            max-width="150"
          />
        </v-col>
        <v-col
          sm
          cols="12"
          align-self="center"
          class="
            d-flex
            justify-center
            align-center
            rounded-t-xl
            grey
            lighten-5
            flex-column
          "
        >
          <span class="primary--text mb-2 title">CREATE NEW ACCOUNT</span>
          <v-divider></v-divider>
          <v-form
            v-on:submit.prevent="register"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-select
              v-model="user.role_id"
              :items="role_id"
              :rules="titleRules"
              item-text="title"
              item-value="id"
              label="I Am "
              single-line
              outlined
              rounded
            ></v-select>
            <v-text-field
              v-model="user.name"
              :rules="nameRules"
              label="Full Name"
              :error-messages="this.error_name"
              prepend-inner-icon="fas fa-user-tie"
              rounded
              outlined
            />
            <v-text-field
              v-model="user.email"
              :rules="emailRules"
              label="E-mail"
              prepend-inner-icon="fas fa-envelope"
              :error-messages="this.error_email"
              rounded
              outlined
              color="primary"
            />
            <v-text-field
              label="Mobile No."
              v-model="user.contact"
              :counter="10"
              :rules="contactRules"
              :error-messages="this.error_contact"
              prepend-inner-icon="fas fa-mobile-alt"
              rounded
              outlined
            />

            <v-text-field
              v-model="user.password"
              :rules="passwordRules"
              label="Password"
              :counter="8"
              :type="ShowPassword ? 'text' : 'password'"
              prepend-inner-icon="fas fa-lock"
              :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="ShowPassword = !ShowPassword"
              :error-messages="this.error_password"
              rounded
              outlined
            />
            <v-text-field
              label="Confirm Password"
              v-model="user.password_confirmation"
              :rules="confirmpasswordRules"
              :type="ShowConfirmPassword ? 'text' : 'password'"
              prepend-inner-icon="fas fa-lock"
              :counter="8"
              :error-messages="this.error_confirm_password"
              :append-icon="ShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="ShowConfirmPassword = !ShowConfirmPassword"
              rounded
              outlined
            />

            <!--<v-checkbox
                v-model="user.remember_me"
                label="Remember Me"
                color="info"
                value=""
                class="mb-4"
              />-->

            <v-btn
              color="info"
              type="submit"
              rounded
              block
              @click="validate"
              :disabled="!valid"
            >
              REGISTER
            </v-btn>
          </v-form>
        </v-col>
        <v-col
          sm
          cols="12"
          align-self="center"
          class="
            d-flex
            justify-center
            align-center
            rounded-b-xl
            grey
            lighten-5
            flex-column
          "
        >
          <div>
            Already have an account ?
            <router-link to="/auth/login" class="routerlink"
              >Sign In</router-link
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Authentication from "../../api/Authentication";
import { mapActions } from "vuex";
export default {
  name: "registration",
  data() {
    return {
      valid: true,
      loading: false,
      snackbar: false,
      snackbar_color: "",
      text: "",
      ShowPassword: false,
      ShowConfirmPassword: false,
      error_name: "",
      error_message: "",
      error_email: "",
      error_contact: "",
      error_password: "",
      error_confirm_password: "",
      user: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_id: "",
      },
      role_id: [
        { title: "Owner", id: 2 },
        { title: "Builder", id: 3 },
        { title: "Broker", id: 4 },
      ],
      errors: {},
      titleRules: [(v) => !!v || "name is required"],

      nameRules: [(v) => !!v || "name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid.",
      ],

      contactRules: [
        (v) => !!v || "Contact Number is required",
        (v) => !isNaN(parseInt(v)) || "Contact Number must be digit.",
        (v) =>
          (v && v.length >= 10) || "Contact Number must have atleast 10 digit",
        (v) =>
          (v && v.length <= 10) ||
          "Contact Number exceeds maximum allowed number",
      ],

      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "password at least 8 characters.",
      ],

      confirmpasswordRules: [
        (v) => !!v || "Confirm Password is required",
        (v) => v.length >= 8 || "Confirm password at least 8 characters.",
        (v) => v === this.user.password || "Password dose not match",
      ],
    };
  },
  methods: {
    ...mapActions(["registerUser"]),
    validate() {
      this.$refs.form.validate();
    },
    register: function () {
      this.loading = true;
      Authentication.register(this.user)
        .then((response) => {
          // console.log("response data :", response.data);
          // console.log("response status :", response.status);
          // console.log("response status text :", response.data.message);
          if (response.status == 201) {
            this.loading = false;
            this.$store.commit("SET_MESSAGE", response.data.message);
            window.location.replace("/auth/login");
          }

          // commit('SET_TOKEN',response.data.access_token)
        })
        .catch((err) => {
          if (err.response) {
            //console.log("error data :", err.response.data);
            this.error_message = err.response.data.errors;
            this.error_email = err.response.data.errors.email;
            this.error_contact = err.response.data.errors.contact;
            //console.log('error status :',error.response.status);
          }
          this.loading = false;
          this.snackbar_color = "error";
          this.text = err.response.message;
          this.snackbar = true;
        });
    },
  },
};
</script>
<style scoped>
.logo-bg {
  background-image: linear-gradient(
    -90deg,
    rgba(23, 234, 217, 0.9) 0%,
    rgba(96, 120, 234, 0.8) 100%
  );
}
</style>
